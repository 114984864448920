<template>
  <div class="d-flex flex-column">
    <li v-for="(item,index) in data" class="d-flex align-items-center mt-1" :key="index">
      <div class="d-flex align-items-center">
        <span class="bullet mr-1" :class="type | typeFilter(itemCls,item.style)"></span>
        <slot :item="item">{{ 'string'=== typeof(item) ? item : item.data}}</slot>
      </div>
    </li>
  </div>
</template>

<script>

export default {
  name: 'bullet',
  props: {
    // dot vertical  line
    type: {
      type: String,
      default: '',
    },
    itemCls: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    typeFilter(type, cls, style) {
      cls = cls !== undefined ? cls : ''
      style = style !== undefined ? style : ''
      if (type) {
        return `bullet-${type} ${cls} ${style}`
      }
      return `${cls} ${style}`
    },
  },
  methods: {

  },
}
</script>

<style scoped>

</style>
