<template>
  <div class="card">
    <div class="card-body">
      <h3 class="text-muted x-text-bold">默认</h3>
      <bullet :data="data"></bullet>

      <h3 class="mt-2 text-muted x-text-bold">垂直 vertical</h3>
      <bullet :data="data" type="vertical"></bullet>
      <h3 class="mt-2 text-muted x-text-bold">圆点 dot</h3>
      <bullet :data="data" type="dot"></bullet>

      <h3 class="mt-2 text-muted x-text-bold">线 line</h3>
      <bullet :data="data" type="line"></bullet>

      <h3 class="mt-2 text-muted x-text-bold">自定义</h3>
      <bullet :data="data" itemCls="x-h-15px x-w-25px">
        <template slot-scope="scope">
          自定义：{{scope.item}}
        </template>
      </bullet>
    </div>
  </div>
</template>

<script>

import Bullet from '@core/components/cx/bullet/index.vue'

export default {
  name: 'example',
  components: {
    Bullet,
  },
  data() {
    return {
      data: [{ style: 'bg-primary', data: '11111' },
        { style: 'bg-danger', data: '2222' },
        { style: 'bg-success', data: '3333' },
        { style: 'bg-info', data: '444' },
        '5555'],
    }
  },
}
</script>

<style scoped>

</style>
